import React, { useEffect } from "react"
import { Flex, Box } from "rebass"
import { colors } from "../../etc/theme"

import { useGetProducerMediaStatsQuery, useGetSettingsQuery } from "../../libs/mediaServer"
import { useDeleteMetricsTokenMutation, useGetMetricsTokenQuery, useGetUserQuery } from "../../libs/api"
import { useCreateWebRTCOutboundMetricsMutation } from "../../libs/metrics-api"
import { auth } from "../../etc/firebase"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useParams } from "react-router-dom"
import { VR_TRACK_ID } from "../../helpers"
import { closeClient } from "../../libs/metrics-api/api"

type Props = {
  producerId: string
  trackId: string
}

export const MediaStats: React.FC<Props> = ({ producerId, trackId }) => {
  const params = useParams()
  const eventId = params.eventId as string

  const { data: user } = useGetUserQuery(auth.currentUser?.uid?.toString() || skipToken)
  const { data: stats } = useGetProducerMediaStatsQuery(producerId)
  const { data: settings } = useGetSettingsQuery()

  const gatherMediaStats =
    (trackId === VR_TRACK_ID && settings?.gatherMediaStats.vr) ||
    (trackId !== VR_TRACK_ID && settings?.gatherMediaStats.overlays)

  const { data: metricsTokenData } = useGetMetricsTokenQuery(gatherMediaStats ? undefined : skipToken)

  const [addMetrics] = useCreateWebRTCOutboundMetricsMutation()
  const [deleteMetricsToken] = useDeleteMetricsTokenMutation()

  useEffect(() => {
    if (!stats || !metricsTokenData || !user?.id) {
      return
    }

    Object.values(stats).forEach((mediaStats) => {
      addMetrics({
        eventId,
        trackId,
        userId: user.id,
        token: metricsTokenData.token,
        org: metricsTokenData.org,
        bucket: metricsTokenData.bucket,
        metrics: {
          timestamp: mediaStats.timestamp,
          kind: mediaStats.kind,
          bitrate: mediaStats.bitrate,
          availableOutgoingBitrate: mediaStats.availableOutgoingBitrate,
          bytesSent: mediaStats.bytesSent,
          retransmittedBytesSent: mediaStats.retransmittedBytesSent,
          firCount: mediaStats.firCount,
          nackCount: mediaStats.nackCount,
          pliCount: mediaStats.pliCount,
          frameHeight: mediaStats.frameHeight,
          frameWidth: mediaStats.frameWidth,
          framesEncoded: mediaStats.framesEncoded,
          framesPerSecond: mediaStats.framesPerSecond,
          keyFramesEncoded: mediaStats.keyFramesEncoded,
          headerBytesSent: mediaStats.headerBytesSent,
          totalEncodeTime: mediaStats.totalEncodeTime,
          totalPacketSendDelay: mediaStats.totalPacketSendDelay,
          qualityLimitationReason: mediaStats.qualityLimitationReason,
          qualityLimitationResolutionChanges: mediaStats.qualityLimitationResolutionChanges,
        },
      })
    })
  }, [stats, metricsTokenData, user?.id, settings, addMetrics])

  useEffect(() => {
    if (metricsTokenData) {
      return () => {
        closeClient(metricsTokenData.token).then(() => deleteMetricsToken(metricsTokenData.id))
      }
    }
    // Remove metrics token only on unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return stats ? (
    <>
      {Object.keys(stats).map((rtpId, index) => (
        <Flex
          key={rtpId}
          css={{
            position: "absolute",
            left: "10px",
            top: `${index * 30 + 10}px`,
            cursor: "default",
          }}
        >
          <Box p="4px 8px" bg={colors.blue} mr="10px" css={{ borderRadius: "4px" }}>
            {stats[rtpId].frameWidth}x{stats[rtpId].frameHeight}
          </Box>
          <Box p="4px 8px" bg={colors.green} css={{ borderRadius: "4px" }}>
            {Math.floor(stats[rtpId].bitrate / 1000)} Kb/s
          </Box>
        </Flex>
      ))}
    </>
  ) : null
}
